import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-admin-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {
  mobile = false;

  activeTab = 'home';
  popup = '';

  currentEntity: string | null = null;

  constructor(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const url = e.url;

        if (url.includes('batch')) {
          this.activeTab = 'batch';
          const match = url.match(/\/batch\/([^\/]*)\//);
          if (match && match.length > 0) {
            this.currentEntity = match[1];
          } else {
            this.currentEntity = null;
          }
        } else if (url.includes('stats')) {
          this.activeTab = 'stats';
          this.currentEntity = null;
        } else if (url.includes('users')) {
          this.activeTab = 'users';
          this.currentEntity = null;
        } else if (url.includes('assortment')) {
          this.activeTab = 'assortment';
          this.currentEntity = null;
        } else if (url.includes('new-shop')) {
          this.activeTab = 'new-shop';
          this.currentEntity = null;
        } else {
          this.activeTab = 'home';
        }
      }
    });
  }

  ngOnInit(): void {
    this.onResize();
  }

  close(): void {
    this.popup = '';
  }

  open(name: string): void {
    this.popup = name;
  }

  @HostListener('window:resize')
  onResize(): void {
    if (window.innerWidth < 1099) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
}
