import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppLangComponent } from 'src/app/app-lang.component';
import { CanonicalService } from 'src/app/services/canonical.service';
import { LanguageService } from 'src/app/services/language/language.service';

import { RedirectService } from 'src/app/services/utilities/external.service';

@Component({
  selector: 'app-admin',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppAdminComponent extends AppLangComponent {
  constructor(
    translate: LanguageService,
    route: ActivatedRoute,
    router: Router,
    canonicalService: CanonicalService,
    private redirectService: RedirectService,
    private update: SwUpdate
  ) {
    super(translate, route, router, canonicalService);
    this.update.available.subscribe(() => {
      update
        .activateUpdate()
        .then(() => document.location.reload())
        .catch((e) => console.error('TODO error', e));
    });

    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        // check if there is _app parameter in the url that indicates external app
        if (e.url.includes('?_app=')) {
          const base = e.url.replace(/_app=(main|user|admin|partner)[\&]?/, '');
          const [, app] = e.url.match(/_app=(main|user|admin|partner)/) ?? [];
          if (app) {
            this.redirectService.navigateToApp(base, app).catch((e) => console.error('TODO error', e));
          }
        }
      }
    });
  }
}
