<div class="top-bar">
  <a class="home-link" [routerLink]="'/' | urlLang" [ngClass]="{ active: true }">
    <mat-icon>dashboard</mat-icon>
  </a>
  <nav mat-tab-nav-bar [disablePagination]="true">
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/batch' | urlLang" [active]="activeTab === 'batch'">
      {{ 'sidemenu.batch' | translate }}
    </a>
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/stats' | urlLang" [active]="activeTab === 'stats'">
      {{ 'sidemenu.stats' | translate }}
    </a>
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/users' | urlLang" [active]="activeTab === 'users'">
      {{ 'sidemenu.users' | translate }}
    </a>
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/images' | urlLang" [active]="activeTab === 'images'">
      {{ 'sidemenu.images' | translate }}
    </a>
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/assortment' | urlLang" [active]="activeTab === 'assortment'">
      {{ 'sidemenu.assortment' | translate }}
    </a>
    <a *appHasRole="'admin'" mat-tab-link [routerLink]="'/new-shop' | urlLang" [active]="activeTab === 'new-shop'">
      {{ 'sidemenu.newShop' | translate }}
    </a>
  </nav>
</div>
