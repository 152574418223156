import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';

import { AdminGuard, ContributorGuard } from 'src/app/guards/role.guard';
import { getDefaultLang } from 'src/app/helpers/lang';
import { AppAdminComponent } from './app.component';

const routes: Routes = [
  { path: '', redirectTo: '/' + getDefaultLang(), pathMatch: 'full' },
  {
    path: ':lang',
    canActivate: [AuthGuard],
    component: AppAdminComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [ContributorGuard],
        loadChildren: () => import('./pages/board/board.module').then((m) => m.BoardPageModule),
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'batch',
        loadChildren: () => import('./pages/batch-edit/batch-edit.module').then((m) => m.BatchEditPageModule)
      },
      {
        path: 'stats',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/stats/stats.module').then((m) => m.StatsPageModule),
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersPageModule),
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'edit',
        loadChildren: () => import('src/app/pages/takson-edit/takson-edit.module').then((m) => m.TaksonEditModule),
        canActivate: [ContributorGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'images',
        loadChildren: () => import('./pages/images/images.module').then((m) => m.ImagesPageModule),
        canActivate: [AdminGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'assortment',
        loadChildren: () => import('./pages/assortment/assortment.module').then((m) => m.AssortmentPageModule),
        canActivate: [AdminGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'new-shop',
        loadChildren: () => import('./pages/new-shop/new-shop.module').then((m) => m.NewShopPageModule),
        canActivate: [AdminGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: '404',
        loadChildren: () => import('src/app/pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
      },
      {
        path: '401',
        loadChildren: () => import('src/app/pages/not-authorized/not-authorized.module').then((m) => m.NotAuthorizedPageModule)
      },
      { path: '**', redirectTo: '/' + getDefaultLang() + '/404' }
    ]
  },
  { path: '**', redirectTo: '/' + getDefaultLang() + '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
