import { APIBaseTaxon, APICollectionTaxon, APISearchTaxon, APIThumbTaxon } from '../api/models';
import { notEmpty } from '../helpers/assertions';
import { environment } from 'src/environments/environment';

export interface ComplexUrl {
  url: string;
  query: { [key: string]: string | number };
}

export class BaseTaxon implements APIBaseTaxon {
  latinName: string;
  taxonId: string;
  variety?: string;
  localName?: string;

  raw: boolean;

  taxonPageLink: ComplexUrl;
  taxonParentPageLink: ComplexUrl;
  plantPageLink: ComplexUrl;

  constructor(data: APIBaseTaxon) {
    this.latinName = data.latinName;
    this.variety = data.variety;
    this.taxonId = data.taxonId;
    this.localName = data.localName;

    this.raw = this.taxonId.startsWith('wfo-');

    this.taxonPageLink = this.getTaxonPageLink();
    this.taxonParentPageLink = this.getTaxonParentPageLink();
    this.plantPageLink = this.getPlantPageLink();
  }

  public getFullName(): string {
    if (!this.taxonId) {
      return 'null';
    }
    const species = this.localName || this.latinName;
    const variety = this.variety ? ' `' + this.variety + '`' : '';

    return species + variety;
  }

  private getTaxonPageLink() {
    return { url: '/takson/' + this.latinName + (this.variety ? '/' + this.variety : ''), query: { _app: 'main' } };
  }
  private getTaxonParentPageLink() {
    return { url: '/takson/' + this.latinName, query: { _app: 'main' } };
  }
  private getPlantPageLink() {
    return { url: '/photos/plant/' + this.latinName + (this.variety ? '/' + this.variety : ''), query: { _app: 'user' } };
  }

  static merge(...objects: BaseTaxon[]): BaseTaxon {
    const data: Partial<BaseTaxon> = {};
    objects.forEach((o) => {
      data.taxonId = o.taxonId;
      if (notEmpty(o.latinName)) data.latinName = o.latinName;
      if (notEmpty(o.variety)) data.variety = o.variety;
      if (notEmpty(o.localName)) data.localName = o.localName;
    });
    return new BaseTaxon(data as BaseTaxon);
  }
}

export class ThumbTaxon extends BaseTaxon {
  thumb?: string;
  silhouette?: string;

  constructor(data: APIThumbTaxon) {
    super(data);
    this.thumb = data.thumb;
    this.silhouette = environment.storageUrl + '/thumb-' + this.taxonId + '.png';
  }
}
export class SearchTaxon extends ThumbTaxon implements APISearchTaxon {
  names: string[];
  synonyms?: string;
  synonymList: string[];

  matchType: 'latin' | 'variety' | 'main' | 'other' | 'synonym';

  matchedNames?: string;
  matchedSynonyms?: string;
  varietiesCount?: number;

  constructor(data: APISearchTaxon) {
    super(data);
    this.matchType = data.matchType;

    this.names = data.names || [];

    this.synonyms = data.synonyms;
    this.synonymList = data.synonyms?.split(',').map((s) => s.trim()) || [];

    this.matchedNames = data.matchedNames;
    this.matchedSynonyms = data.matchedSynonyms;
    this.varietiesCount = data.varietiesCount;
  }
}

export class CollectionTaxon extends BaseTaxon implements APICollectionTaxon {
  //names: string[];

  inPlants?: boolean;
  inFavourites?: boolean;
  inShopping?: boolean;

  constructor(data: APICollectionTaxon) {
    super(data);
    //this.names = data.localName?.split(',') || [];

    if (data.collectionTypes?.length) {
      this.inPlants = data.collectionTypes.includes('plants');
      this.inFavourites = data.collectionTypes.includes('favourites');
      this.inShopping = data.collectionTypes.includes('shopping');
    }
  }
}
