import { Component, OnDestroy, OnInit } from '@angular/core';

import { LanguageService } from './services/language/language.service';

import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { getDefaultLang } from './helpers/lang';

import { slideLeftRightAnimation } from './animations/route-animations';
import { CanonicalService } from './services/canonical.service';

@Component({
  selector: 'app-lang',
  template: `<div [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>`,
  styles: [
    `
      div {
        display: contents;
      }
    `
  ],
  animations: [slideLeftRightAnimation]
})
export class AppLangComponent implements OnInit, OnDestroy {
  onLangChange?: Subscription;

  constructor(
    private translate: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private canonicalService: CanonicalService
  ) {
    const langs = ['pl', 'en'];
    this.translate.addLangs(langs);

    this.initTranslate();

    this.route.paramMap.subscribe((params) => {
      const newLang = params.get('lang') || 'en';
      if (this.translate.currentLang !== newLang || !this.translate.currentLang) {
        if (this.translate.getLangs().includes(newLang)) {
          this.initTranslate(newLang);
        } else {
          void this.router.navigateByUrl('/' + this.translate.currentLang + '/404');
        }
      }
    });

    this.canonicalService.setCanonicalUrl();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.canonicalService.setCanonicalUrl();
    });
  }

  prepareRoute(outlet: RouterOutlet): boolean {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private initTranslate(lang?: string) {
    if (!lang) {
      lang = getDefaultLang();
    }
    this.translate.setLang(lang);
  }

  ngOnInit(): void {
    this.updateLanguage();
    this.onLangChange = this.translate.onLangChange.subscribe(() => {
      this.updateLanguage();
    });
  }

  ngOnDestroy(): void {
    if (this.onLangChange !== undefined) {
      this.onLangChange.unsubscribe();
    }
  }

  /**
   * Update the language in the lang attribute of the html element.
   */
  updateLanguage(): void {
    document.documentElement.lang = this.translate.currentLang;
  }
}
