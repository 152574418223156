import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { RedirectService } from 'src/app/services/utilities/external.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppRootComponent {
  constructor(private router: Router, private redirectService: RedirectService, private update: SwUpdate) {
    this.update.available.subscribe(() => {
      update
        .activateUpdate()
        .then(() => document.location.reload())
        .catch((e) => console.error('TODO error', e));
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        // check if there is _app parameter in the url that indicates external app
        if (e.url.includes('?_app=')) {
          const base = e.url.replace(/_app=(main|user|admin|partner)[\&]?/, '');
          const [, app] = e.url.match(/_app=(main|user|admin|partner)/) ?? [];
          if (app) {
            this.redirectService.navigateToApp(base, app).catch((e) => console.error('TODO error', e));
          }
        }
      }
    });
  }
}
