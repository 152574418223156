import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { UserIdleModule } from 'angular-user-idle';

import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';

import { environment } from 'src/environments/environment';

import { CustomMaterialModule } from 'src/app/components/custom.material.module';
import { ComponentsModule } from 'src/app/components/components.module';

import { PipeModule } from 'src/app/pipes/pipe.module';
import { DirectivesModule } from 'src/app/directives/directives.module';

import { UNIQUE_ID } from 'src/app/services/utilities/external.service';

import { ApiModule } from 'src/app/api/api.module';
import { AdminComponentsModule } from './components/components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppAdminComponent } from './app.component';
import {
  createTranslateLoader,
  keycloakInitializer,
  appInitializerFactory,
  API_INTERCEPTOR_PROVIDER
} from 'src/app/app.module.initializer';
import { AppLangModule } from 'src/app/app.module';
import { AppRootComponent } from './app-root.component';
import { ApiInterceptor } from 'src/app/api.interceptor';
import { GlobalErrorHandler } from 'src/app/error.handler';

registerLocaleData(localePl, 'pl-PL');

@NgModule({
  declarations: [AppAdminComponent, AppRootComponent],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.newApiUrl }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    CustomMaterialModule,
    AdminComponentsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader('admin'),
        deps: [HttpClient]
      }
    }),
    ComponentsModule,
    PipeModule,
    DirectivesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    AngularStickyThingsModule,
    AppLangModule,
    KeycloakAngularModule,
    UserIdleModule.forRoot({ idle: 1800, timeout: 1, ping: 120 })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    CookieService,
    {
      provide: UNIQUE_ID,
      useValue: 'admin'
    },
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer('admin'),
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule {}
