import { Image } from './image';
import { User } from './user';
import { Feature, Features } from './features';
import { getDefaultLang } from '../helpers/lang';

import { environment } from 'src/environments/environment';
import { BaseTaxon } from './taxon';
import { APIFeature } from '../api/models';

export class LangName {
  lang: string;
  name: string;
  nameId?: string;
  priority?: number;
  // owner?: User;

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    this.lang = data.lang;
    this.name = data.name;
    this.nameId = data.taksonNameId;
    // this.owner = new User(data);
    this.priority = data.priority ? parseInt(data.priority, 10) : 0;
  }

  public static getPreferredLocal(names: LangName[]): LangName | undefined {
    const lang = getDefaultLang();
    return names.find((n: LangName) => n.lang === lang && n.priority === 0);
  }

  public static getSynonymsLocal(names: LangName[]): LangName[] {
    const lang = getDefaultLang();
    return names.filter((n: LangName) => n.lang === lang && n.priority !== 0);
  }
}

export class Info {
  para: string;
  category: string;
  infoId?: string;
  lang: string;
  date: string;
  owner: User;
  source: string;

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    this.para = data.text;
    this.category = data.category_id;
    this.infoId = data.info_id;
    this.lang = data.lang;
    this.date = data.updated_at;
    this.source = data.source;
    this.owner = new User(data);
  }
}

export class ContributorData {
  userId: number;
  note: string;
  image: string;
  ready: number;

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    // if(!data){
    //   return;
    // }
    this.userId = parseInt(data.userId, 10);
    this.note = data.note;
    this.image = data.image;
    this.ready = parseInt(data.ready, 10);
  }
}

export class TaksoUnit extends BaseTaxon {
  taxonId = '';
  latinName = '';
  brand = '';
  authorship = '';
  variety?: string;
  localName?: string;
  names: LangName[] = [];

  namesLegal = '';
  legal: { registered: string; pbr: string } = { registered: '', pbr: '' };

  synonyms?: string;

  mainImage?: Image;
  images: Image[] = [];
  infos: Info[] = [];
  raw = true;
  status = '';

  features?: Features;
  // silhouette: floralType;
  // flowerShape: flowerShape;
  // colors: string[];

  thumb?: string;
  silhouette?: string;

  varieties?: TaksoUnit[];
  // unused so far
  // activities?: Activity[];
  // imageCount?: number;
  owner: User | null = null;

  contributes?: ContributorData;

  linkName = '';
  // links for navigation by taksons
  pageLink = '';
  editLink = '';
  parentPageLink = '';

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    super(data as BaseTaxon);
    if (data === null || data === undefined) {
      return;
    }
    if (typeof data === 'string') {
      this.taxonId = data;
      return;
    }

    this.taxonId = data.taxonId || data.taksonOriginal;
    this.raw = data.taxonId === undefined || data.taxonId === null || data.taxonId.toString().includes('wfo-');
    this.status = data.status;

    // TODO: compact to single property with object
    this.latinName = data.latinName;
    this.synonyms = data.synonyms;
    this.authorship = data.authorship;
    this.brand = data.brand;
    this.namesLegal = (data.registered === '1' ? '®' : '') + (data.pbr === '1' ? ' PBR' : '');
    this.legal = {
      registered: data.registered,
      pbr: data.pbr
    };

    if (data.variety && data.variety !== '') {
      this.variety = data.variety;
    }
    if (data.varieties && Array.isArray(data.varieties) && data.varieties.length > 0) {
      this.varieties = data.varieties.map((v: any) => new TaksoUnit(v));
    }

    // unused so far
    // if (data.activities && Array.isArray(data.activities)) {
    //   this.activities = data.activities.map((a: any) => new Activity(a));
    // }

    this.names = data.names ? data.names.map((n: any) => new LangName(n)) : [];
    const topName = this.names.find((n) => n.priority === 0);
    this.localName = topName ? topName.name : this.names.length > 0 ? this.names[0].name : undefined;

    this.mainImage = data.mainImage ? new Image(data.mainImage) : undefined;
    this.thumb = this.mainImage?.thumb;
    this.silhouette = environment.storageUrl + '/thumb-' + this.taxonId + '.png';

    this.images = data.images ? data.images.map((i: any) => new Image(i)) : [];

    this.infos = data.infos ? data.infos.map((i: any) => new Info(i)) : [];
    this.features = data.features ? new Features(data.features.map((f: APIFeature) => new Feature<any>(f))) : undefined;
    this.owner = data.owner ? new User(data.owner) : null;

    if (data.contributes && data.contributes !== null && data.contributes.userId !== null) {
      this.contributes = new ContributorData(data.contributes);
    }

    this.linkName = this.getLinkName();
    this.pageLink = this.getPageLink();
    this.editLink = this.getEditLink();
    this.parentPageLink = this.getParentPageLink();
  }

  static create(data: Partial<TaksoUnit>): TaksoUnit {
    const newTakso = new TaksoUnit({});
    newTakso.taxonId = data.taxonId || '';
    newTakso.raw = data.raw || false;
    newTakso.latinName = data.latinName || '';
    newTakso.authorship = data.authorship || '';
    newTakso.variety = data.variety;
    newTakso.brand = data.brand || '';

    if (data.varieties) {
      newTakso.varieties = [...data.varieties];
    }
    // newTakso.imageCount = data.imageCount;

    newTakso.names = !data.names ? [] : [...data.names];

    newTakso.mainImage = data.mainImage;
    newTakso.images = data.images ? data.images.map((i: any) => new Image(i)) : [];
    newTakso.infos = data.infos ? data.infos.map((i: any) => new Info(i)) : [];
    newTakso.owner = data.owner || null;
    return newTakso;
  }

  getFullName(): string {
    if (!this.taxonId) {
      return 'null';
    }
    const species = this.getSpeciesName();

    const variety = this.variety ? ' `' + this.variety + '`' : '';
    const brand = this.brand && this.brand !== null ? ` ${this.brand} ` : '';

    return species + brand + variety + this.namesLegal;
  }

  getSpeciesName(): string {
    let species = this.latinName;
    if (this.localName) {
      species = this.localName;
    }

    let preferred: LangName | undefined;
    if (this.names.length > 0) {
      preferred = LangName.getPreferredLocal(this.names);
    }
    if (preferred) {
      species = preferred.name;
    }
    return species;
  }
  getOtherNames(): LangName[] {
    return LangName.getSynonymsLocal(this.names);
  }

  // change with changing bucket names
  private getLinkName() {
    return this.latinName + (this.variety ? ' `' + this.variety + '`' : '');
  }

  private getPageLink() {
    return '/takson/' + this.latinName + (this.variety ? '/' + this.variety : '');
  }
  private getEditLink() {
    return '/edit/' + this.latinName + (this.variety ? '/' + this.variety : '');
  }
  private getParentPageLink() {
    return '/takson/' + this.latinName;
  }
}
